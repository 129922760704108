import { io } from "socket.io-client";

// Check if we're running in the browser
const isBrowser = typeof window !== "undefined";

// Define the URL (replace with your actual URL or use environment variables)
const SOCKET_URL = "https://socdev.superkos.id/"; //|| "http://localhost:3000"; // Default to localhost if no URL is provided

// Create the socket connection if we're in the browser
export const socket = isBrowser ? io(SOCKET_URL) : {};
const socketService = {
  register: (phoneNumber) => {
    socket.emit("register", phoneNumber);
  },
  joinRoom: (userId, phoneNumber) => {
    socket.emit("join_room", userId, phoneNumber);
  },
  sendMessage: (data) => {
    socket.emit("send_message", data);
  },
  onRecieveTestMessage: (callback) => {
    socket.on("whatsapp-auth-status", (message) => {
      callback(message);
    });
  },
  getUnreadCount: (userId) => {
    socket.emit("get_unread_count", { userId });
  },

  markAsRead: (senderId, receiverId) => {
    socket.emit("mark_as_read", { senderId, receiverId });
  },

  broadcastMessage: (propertyId, messageContent, media) => {
    socket.emit("broadcast_message", { propertyId, messageContent, media });
  },

  syncStatus: (senderId, receiverId, status) => {
    socket.emit("sync_status", { senderId, receiverId, status });
  },

  onMessageReceived: (callback) => {
    socket.on("receive-message", (message) => {
      callback(message);
    });
  },

  getNewNotification: (callback) => {
    socket.on("new_notification", (message) => {
      callback(message);
    });
  },
  onWhatsAppConnected: (callback) => {
    socket.on("whatsapp-auth-status", (message) => {
      callback(message);
    });
  },
  
  onReceivedOutGoingMessage: (callback) => {
    socket.on("outgoing-message", (message) => {
      callback(message);
    });
  },
  
  
  onUnreadCount: (callback) => {
    socket.on("unread_count", (count) => {
      callback(count);
    });
  },
  onBroadcastSuccess: (callback) => {
    socket.on("broadcast_success", (data) => {
      callback(data);
    });
  },

  onSyncStatus: (callback) => {
    socket.on("receive_status_update", (statusUpdate) => {
      callback(statusUpdate);
    });
  },

  onDisconnect: (callback) => {
    socket.on("disconnect", () => {
      callback();
    });
  },

  onError: (callback) => {
    socket.on("error", (error) => {
      callback(error);
    });
  },

  onAuthenticated: (callback) => {
    socket.on("authenticated", (message) => {
      console.log("message == ", message);
      callback(message);
    });
  },

  // Additional event handlers as necessary
};

export default socketService;
