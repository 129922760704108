
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import "moment/locale/id";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "react-awesome-lightbox/build/style.css";
import "../components/PdfViewer/pdfviewer.css";
import "../components/RichTextEditor/richtexteditor.css";
import "react-phone-number-input/style.css";
import "shepherd.js/dist/css/shepherd.css";
import "../styles/global.css";
import * as Yup from "yup";
import Application from "../components/Application";
import config from "../config";
import CssBaseline from "@material-ui/core/CssBaseline";
import Head from "next/head";
import { InjectStoreContext } from "../store";
import { Poppins } from "next/font/google";
import theme from "../styles/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Notifications from "../components/Notifications/Notifications";
import ReactNotificationComponent from "../components/Notifications/ReactNotification";
const APP_TITLE = [config.APP_NAME, "Landlord"];
if (config.NODE_ENV === "development") {
    APP_TITLE.push("DEV");
}
else if (config.DEMO_MODE) {
    APP_TITLE.push("DEMO");
}
Yup.addMethod(Yup.string, "emails", function (message) {
    return this.test({
        name: "emails",
        message: message || "${path} one of the emails is invalid or is not unique",
        test: (value) => {
            if (value == null) {
                return true;
            }
            const schema = Yup.string().email();
            const emails = value.replace(/\s/g, "").split(",");
            return (emails.every((email) => schema.isValidSync(email)) &&
                emails.length === new Set(emails).size);
        }
    });
});
const poppins = Poppins({
    weight: ["300", "400", "500", "700"],
    style: ["normal", "italic"],
    subsets: ["latin", "latin-ext"]
});
function MyApp(props) {
    const { Component, pageProps } = props;
    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);
    return (<>
      <Head>
        <title>{APP_TITLE.join(" - ")}</title>
        {/* PWA primary color */}
        <meta name="theme-color" content={theme.palette.primary.main}/>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width"/>
        <link rel="shortcut icon" href={`${config.BASE_PATH}/favicon.svg`}/>
        <link href="https://cdn.jsdelivr.net/npm/remixicon@2.5.0/fonts/remixicon.min.css" rel="stylesheet"/>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
      </Head>
      <main className={poppins.className} style={{ fontFamily: "poppins" }}>
        <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_G_SECRET_KEY}>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <InjectStoreContext initialData={pageProps.initialState?.store}>
              <Application {...pageProps}>
                <Notifications />
                {/* <ReactNotificationComponent/> */}
                <Component {...pageProps}/>
              </Application>
            </InjectStoreContext>
          </ThemeProvider>
        </GoogleOAuthProvider>
      </main>
    </>);
}
const __Next_Translate__Page__195d150bc7e__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__195d150bc7e__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  