import React from 'react';
import { useField, useFormikContext } from 'formik';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { Box, FormControl, Grid, Input, InputAdornment, InputLabel, makeStyles, Popover } from '@material-ui/core';

// Define styles for the HexColorPicker child elements
const useStyles = makeStyles(() => ({
  saturationPointer: {
    "& .react-colorful__saturation-pointer": {
      width: "16px",
      height: "16px",
      borderRadius: "100%",
      border: "2px solid white", // Optional extra style
    },
    "& .react-colorful__hue-pointer": {
      width: "18px",
      height: "18px",
      borderRadius: "100%",
      border: "2px solid white", // Optional extra style
    }
  },
}));

export function ColorPickerField({ label, disabled, ...props }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);


  const [field, meta, helpers] = useField(props);
  const { isSubmitting, setFieldValue } = useFormikContext();
  const hasError = !!(meta.touched && meta.error);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (evt) => {
    setFieldValue(field.name, evt)
    // helpers.setValue(evt);
  }
  return (
    <FormControl margin="normal" fullWidth>
      {label ? (
        <InputLabel htmlFor={props.name} error={hasError} shrink>
          {label}
        </InputLabel>
      ) : null}

      <Input
        error={hasError}
        disabled={disabled || isSubmitting}
        endAdornment={
          <InputAdornment position="end">
            <Box
              style={{
                // padding: "4px",
                backgroundColor: `${field.value}`,
                height: "1.8em",
                width: "1.8em",
                borderRadius: "10px",
                border: "0.1em solid #fff",
                boxShadow: "0 0 0 0.1em rgba(0, 0, 0, 0.1), inset 0 0 0 0.1em rgba(0, 0, 0, 0.1)"
              }}
              onClick={handleClick}
            />
          </InputAdornment>
        }
        {...props}
        {...field}
        type='text'
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            padding: "2px"
          }
        }}
      >
        <Box style={{
          "& .react-colorful__saturation-pointer": {
            width: "16px",
            height: "16px",
            "border-radius": "3px"
          }
        }}>
          <HexColorPicker
            color={field.value}
            onChange={handleChange}
            className={classes.saturationPointer}
          // {...props}
          />
        </Box>
      </Popover>
    </FormControl>
  );
};