import { offset } from "@floating-ui/dom";
import { action, flow, makeObservable, observable } from "mobx";
import Shepherd from "shepherd.js";
// import 'shepherd.js/dist/css/shepherd.css';

export default class GuideTour {
  constructor() {
    this.isTourActive = false;
    this.steps = []; // array of stepTour
    this.tour = null;

    makeObservable(this, {
      isTourActive: observable,
      steps: observable,
      setSteps: action,
      startTour: action,
      endTour: action,
      setupTour: action,
    });
  }

  // TODO: 
  setSteps(newSteps) {
    this.steps = newSteps;
  }

  setupTour(newSteps, checkboxButton = false) {
    this.tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        cancelIcon: {
          enabled: true
        },
        canClickTarget: false,
        classes: 'shepherd-theme-arrows',
        scrollTo: true,
        scrollToHandler: (element) => {
          if (element) {
            const offset = 300;
            const modalContainer = document.querySelector('.MuiDialog-paperScrollPaper')
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;

            if (!!modalContainer) {
              // Calculate the offset of the target element relative to the modal container
              const elementOffsetTop = element.getBoundingClientRect().top + modalContainer.scrollTop;

              // Scroll the modal to bring the target element into view
              modalContainer.scrollTo({
                top: elementOffsetTop - offset, // Adjust for modal's position
                behavior: 'smooth',
              });
            } else {
              // Fallback to default scrolling behavior (scroll the page)
              window.scrollTo({
                top: elementPosition - offset,
                behavior: 'smooth',
              });
            }
          } else {
            console.warn('Target element not found for scrolling.');
          }
        },
        floatingUIOptions: {
          middleware: [offset(10)]
        },
        when: {
          show() {
            // Tour Steps
            const currentStep = Shepherd.activeTour?.getCurrentStep();
            const currentStepElement = currentStep?.getElement();
            const currentStepNumber = Shepherd.activeTour?.steps.indexOf(currentStep) + 1;
            const stepsLength = Shepherd.activeTour?.steps.length;

            // Existed Component
            const shepherdContent = currentStepElement?.querySelector('.shepherd-content');
            const shepherdHeader = currentStepElement?.querySelector('.shepherd-header');
            const shepherdTitle = currentStepElement?.querySelector('.shepherd-title');
            const shepherdFooter = currentStepElement?.querySelector('.shepherd-footer');

            // Progress
            const progress = document.createElement('h3');
            progress.className = "shepherd-progress-step"
            progress.style['margin-block'] = "0";
            progress.style['font-weight'] = 400;
            progress.style['opacity'] = 0.5;
            progress.innerText = `${currentStepNumber}/${stepsLength}`;

            // Checkbox
            // const checkBoxLabel = document.createElement("label");
            // checkBoxLabel.className = "shepherd-dont-show-checkbox"

            // const checkBoxInput = document.createElement("input");
            // checkBoxInput.type = "checkbox";
            // checkBoxInput.id = "dont-show-again";

            // checkBoxLabel.appendChild(checkBoxInput);
            // checkBoxLabel.appendChild(document.createTextNode("Jangan tampilkan lagi"));
            // checkBoxLabel.style['width'] = 'fit-content';
            // checkBoxLabel.style['display'] = 'flex';
            // checkBoxLabel.style['align-items'] = 'center';
            // checkBoxLabel.style['gap'] = '4px';
            // checkBoxLabel.style['margin'] = '0 12px 12px';
            // checkBoxLabel.style['opacity'] = 0.75;

            if (!shepherdTitle) {
              progress.style['margin-right'] = 'auto';
              shepherdHeader?.insertBefore(progress, currentStepElement.querySelector('.shepherd-cancel-icon'));
            } else {
              progress.style['margin-right'] = '5px';
              shepherdHeader?.insertBefore(progress, shepherdTitle);
            }

            // if (currentStepNumber == stepsLength) {
            //   shepherdContent.insertBefore(checkBoxLabel, shepherdFooter);
            // }
          }
        }
      },
    });

    // Add steps
    this.tour.addSteps(newSteps);
  }

  setSteps(newSteps) {
    this.steps = newSteps;
  }

  startTour() {
    this.isTourActive = true;
    this.tour?.start();
  }

  endTour() {
    this.isTourActive = false;
    this.tour?.cancel();
  }

}
