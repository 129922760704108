import { makeObservable, observable, flow } from 'mobx';
import axios from 'axios';

export default class Address {
  constructor() {
    this.items = [];
    this.cachedProvinces = null;
    this.cachedDistricts = {};
    this.cachedSubDistricts = {};
    this.cachedVillages = {};

    makeObservable(this, {
      items: observable,
      province: flow,
      districts: flow,
      subdistricts: flow,
      villages: flow,
    });
  }

  setItems() {
    this.items = [];
  }

  // Fetch province data with caching
  *province() {
    if (this.cachedProvinces) {
      return { status: 200, data: this.cachedProvinces };
    }

    try {
      const response = yield axios.get('https://ibnux.github.io/data-indonesia/provinsi.json');
      this.cachedProvinces = response.data; // Cache the response
      return { status: 200, data: this.cachedProvinces };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  // Fetch districts data with caching
  *districts(province) {
    if (this.cachedDistricts[province]) {
      return { status: 200, data: this.cachedDistricts[province] };
    }

    try {
      const response = yield axios.get(`https://ibnux.github.io/data-indonesia/kabupaten/${province}.json`);
      this.cachedDistricts[province] = response.data; // Cache by province code
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  // Fetch subdistricts data with caching
  *subdistricts(district) {
    if (this.cachedSubDistricts[district]) {
      return { status: 200, data: this.cachedSubDistricts[district] };
    }

    try {
      const response = yield axios.get(`https://ibnux.github.io/data-indonesia/kecamatan/${district}.json`);
      this.cachedSubDistricts[district] = response.data; // Cache by district code
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }

  // Fetch villages data with caching
  *villages(subdistrict) {
    if (this.cachedVillages[subdistrict]) {
      return { status: 200, data: this.cachedVillages[subdistrict] };
    }

    try {
      const response = yield axios.get(`https://ibnux.github.io/data-indonesia/kelurahan/${subdistrict}.json`);
      this.cachedVillages[subdistrict] = response.data; // Cache by subdistrict code
      return { status: 200, data: response.data };
    } catch (error) {
      return { status: error?.response?.status, error: error?.response?.data };
    }
  }
}
