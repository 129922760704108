import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const GetScreenType = () => {
  const theme = useTheme();

  // Define screen type using breakpoints
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Up to 600px
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // Between 600px and 960px
  const isDesktop = useMediaQuery(theme.breakpoints.up("md")); // Above 960px

  let screenType = "Unknown";

  if (isMobile) {
    screenType = "Mobile";
  } else if (isTablet) {
    screenType = "Tablet";
  } else if (isDesktop) {
    screenType = "Desktop";
  }

  return { screenType };
};

export default GetScreenType;
