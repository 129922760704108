import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { onMessageListener, requestForToken } from "../../firebaseConfig";
import { Bell } from 'lucide-react';
import { LogoNav2Illustration } from "../Illustrations";

const Notifications = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });

  // Request for token when the component mounts
  requestForToken();

  // Only show the toast when there's a new notification
  useEffect(() => {
    if (notification?.title) {
      toast.dismiss(); // Dismiss any existing toast to avoid multiple toasts at the same time
      toast(<ToastDisplay />);
    }
  }, [notification]);

  function ToastDisplay() {
    return (
      <div style={toastStyle}>
        <div style={toastContent}>
          {/* <LogoNav2Illustration style={iconStyle} /> */}
          <div>
            <p style={titleStyle}>
              <b>{notification?.title}</b>
            </p>
            <p style={bodyStyle}>{notification?.body}</p>
          </div>
        </div>
      </div>
    );
  }

  // Firebase listener to handle incoming messages
  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  return <Toaster position="top-right" reverseOrder={false} />;
};

// Styles for the notification
const toastStyle = {
  display: "flex",
  alignItems: "center",
  padding: "10px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  width: "300px",
  fontFamily: "Arial, sans-serif",
};

const toastContent = {
  display: "flex",
  alignItems: "center",
};

const iconStyle = {
  fontSize: "24px",
  marginRight: "10px",
  color: "#4CAF50",
};

const titleStyle = {
  fontSize: "16px",
  margin: "0",
  fontWeight: "bold",
};

const bodyStyle = {
  fontSize: "14px",
  margin: "0",
  color: "#333",
};

export default Notifications;
