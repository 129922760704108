const startButton = [
  {
    text: 'Selanjutnya',
    action: function () {
      this.next();
    },
  },
]

const backNextButton = [
  {
    text: 'Kembali',
    action: function () {
      this.back();
    },
  },
  {
    text: 'Selanjutnya',
    action: function () {
      this.next();
    },
  },
]

const backCompleteButton = (store, router, handleTabChange) => [
  {
    text: 'Kembali',
    action: function () {
      this.back();
    },
  },
  {
    text: 'Selesai',
    action: async function () {
      if (handleTabChange) {
        handleTabChange(null, 0)
      }
      try {
        const dontShowAgainCheckbox = document.getElementById('dont-show-again');

        if (dontShowAgainCheckbox) {
          // hapus params
          const resolvedPath = router.asPath.split('/')[1];
          const pages = {
            [resolvedPath]: true,
          };

          if (dontShowAgainCheckbox.checked) {
            const response = await store.user.skipTour(store.user._id, pages);

            if (response.status === 200) {
              this.complete();
            } else {
              console.warn('Failed to skip tour, status:', response.status);
            }
          } else {
            this.complete();
          }
        } else {
          console.warn('Checkbox element not found!');
          this.complete();
        }
      } catch (error) {
        console.error('Failed to skip tour:', error);
        this.complete();
      }
    },
  }
];

async function handleSkipCurrentTour(store, router, t) {
  const resolvedPath = router.asPath.split('/')[1];
  const pages = { [resolvedPath]: true };

  const { status } = await store.user.skipTour(store.user._id, pages);
  if (status !== 200) {
    switch (status) {
      default:
        return store.pushToastMessage({
          message: t('Something went wrong'),
          severity: 'error',
        });
    }
  }
  this
}

// Landing Page 
export const LandingPageGuide = (t, store, router) => {
  return [
    {
      title: t('Landing Page Tab'),
      text: t('Manage all landing page settings and information for your property here.'),
      attachTo: {
        element: '.shepherd-landingpage-tab',
        on: 'top',
      },
      buttons: startButton,
    },
    {
      title: t('Add Images'),
      text: t('Upload photos to enhance the appearance of your property’s landing page.'),
      attachTo: {
        element: '.shepherd-landingpage-add-images',
        on: 'bottom',
      },
      buttons: backNextButton,
    },
    {
      title: t('Enable Landing Page'),
      text: t('Toggle this option to make your property’s landing page public.'),
      attachTo: {
        element: '.shepherd-landingpage-toggle-display',
        on: 'top',
      },
      buttons: backNextButton,
    },
    {
      title: t('Landing Page Link'),
      text: t('Copy this link to share your property’s details with prospective tenants.'),
      attachTo: {
        element: '.shepherd-landingpage-link',
        on: 'top',
      },
      buttons: backNextButton,
    },
    {
      title: t('WhatsApp Number'),
      text: t('Enter your WhatsApp number to allow tenants to contact you directly.'),
      attachTo: {
        element: '.shepherd-landingpage-whatsapp',
        on: 'right',
      },
      buttons: backNextButton,
    },
    {
      title: t('Tenant Code'),
      text: t('Use this code to help identify prospective tenants for your property.'),
      attachTo: {
        element: '.shepherd-landingpage-tenant-code',
        on: 'left',
      },
      buttons: backNextButton,
    },
    {
      title: t('Address Display'),
      text: t('Select which parts of your property’s address will appear on the landing page.'),
      attachTo: {
        element: '.shepherd-landingpage-address',
        on: 'top',
      },
      buttons: backNextButton,
    },
    {
      title: t('Description'),
      text: t('Write an engaging description to introduce your property to prospective tenants.'),
      attachTo: {
        element: '.shepherd-landingpage-description',
        on: 'top',
      },
      buttons: backNextButton,
    },
    {
      title: t('Property Rules'),
      text: t('Add rules for your property to ensure tenants understand the guidelines.'),
      attachTo: {
        element: '.shepherd-landingpage-rules',
        on: 'top',
      },
      buttons: backNextButton,
    },
    {
      title: t('Property Facilities'),
      text: t('Select general facilities available for tenants at your property.'),
      attachTo: {
        element: '.shepherd-landingpage-facilities-property',
        on: 'top',
      },
      buttons: backNextButton,
    },
    {
      title: t('Room Facilities'),
      text: t('Choose the facilities available in each room for tenants.'),
      attachTo: {
        element: '.shepherd-landingpage-facilities-room',
        on: 'top',
      },
      buttons: backNextButton,
    },
    {
      title: t('Bathroom Facilities'),
      text: t('Select bathroom facilities available for tenants at your property.'),
      attachTo: {
        element: '.shepherd-landingpage-facilities-bathroom',
        on: 'top',
      },
      buttons: backNextButton,
    },
    {
      title: t('Parking Facilities'),
      text: t('Choose the parking facilities available for tenants at your property.'),
      attachTo: {
        element: '.shepherd-landingpage-facilities-parking',
        on: 'top',
      },
      buttons: backNextButton,
    },
    {
      title: t('Save Changes'),
      text: t('Click this button to save all the changes to your property’s landing page.'),
      attachTo: {
        element: '.shepherd-landingpage-save',
        on: 'bottom',
      },
      buttons: backCompleteButton(store, router),
    },
  ];
};

/**
 * Pages Guide
 * @param {*} store 
 * @param {*} router 
 * @param {*} t 
 * @param {*} handleTabChange 
 */
export function dashboardSteps(store, router, t, handleTabChange) {
  return [
    {
      title: t('Number of Room Tenants'),
      text: t('Displays the total number of active room tenants. You can also see the total number of tenants since the beginning.'),
      attachTo: {
        element: '.dashboard-penyewa-kamar',
        on: 'top',
      },
      buttons: startButton,
    },
    {
      title: t('Total Properties'),
      text: t('Shows the total number of properties you are managing in the system.'),
      attachTo: {
        element: '.dashboard-total-properti',
        on: 'top',
      },
      buttons: backNextButton,
    },
    {
      title: t('Occupied Rooms'),
      text: t('The percentage of rooms currently occupied compared to the total available rooms.'),
      attachTo: {
        element: '.dashboard-kamar-terisi',
        on: 'top',
      },
      buttons: backNextButton,
    },
    {
      title: t('Rent Payments This Month'),
      text: t('Tracks the rent payment status for this month, including the number of payments completed and those pending.'),
      attachTo: {
        element: '.dashboard-pembayaran-sewa',
        on: 'top',
      },
      buttons: backNextButton,
    },
    {
      title: t('Profit/Loss for the Last 4 Months'),
      text: t('This graph shows the profit or loss report over the last four months based on your financial data.'),
      attachTo: {
        element: '.dashboard-laba-rugi',
        on: 'bottom',
      },
      buttons: backNextButton,
    },
    {
      title: t('Financial Recap for the Last 12 Months'),
      text: t('This graph helps you track income, expenses, and security deposit returns over the last 12 months.'),
      attachTo: {
        element: '.dashboard-rekap-keuangan',
        on: 'bottom',
      },
      buttons: backNextButton,
    },
    {
      title: t('This Month’s Profit'),
      text: t('This section displays the net profit for the current month and compares it to the previous month’s profit.'),
      attachTo: {
        element: '.dashboard-laba-bulan',
        on: 'top',
      },
      buttons: backNextButton,
    },
    {
      title: t('Activities'),
      text: t('Displays important actions you can take, such as recording payments, adding new properties, or checking room availability.'),
      attachTo: {
        element: '.dashboard-aktivitas',
        on: 'right',
      },
      buttons: backNextButton,
    },
    {
      title: t('Financial Summary'),
      text: t('Brief information about total income, expenses, and security deposits managed in the system.'),
      attachTo: {
        element: '.dashboard-ringkasan-keuangan',
        on: 'top',
      },
      buttons: backCompleteButton(store, router),
    },
  ];
}

export function tenantsSteps(store, router, t, handleTabChange) {
  const isSkipGuide = store.user.isSkipTourGuide.tenants
  const isEmptyTenants = store.tenant.filteredItems.length == 0

  let arrayOfStep = [
    {
      text: t('Welcome to Tenant Page! This guide will explain the features of this page.'),
      attachTo: {
        element: '.shepherd-currentPage',
        on: 'right-start'
      },
      buttons: [
        {
          text: t('Skip Tour'),
          classes: !isSkipGuide ? 'show-skip' : 'hide-skip',
          secondary: true,
          action: function () {
            handleSkipCurrentTour(store, router, t)
            this.complete();
          },
        },
        {
          text: t('Start'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this button to add new tenant.'),
      attachTo: {
        element: '.shepherd-tenantList-addRoomButton',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Use this to filter tenants by tenant name.'),
      attachTo: {
        element: '.shepherd-searchBar',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Use this to filter tenants by status.'),
      attachTo: {
        element: '.shepherd-toggleStatusMenu',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Use this to filter tenants by property.'),
      attachTo: {
        element: '.shepherd-filterPropertyMenu',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this tab to switch the display options, grid view or list view.'),
      attachTo: {
        element: '.shepherd-tenantList-tabsView',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: isEmptyTenants ? !isSkipGuide ? t("Don't show again") : t('Complete') : t('Next'),
          action: async function () {
            if (!isSkipGuide) {
              handleSkipCurrentTour(store, router, t)
            }
            isEmptyTenants ? this.complete() : this.next();
          },
        },
      ],
    },
  ]

  if (!isEmptyTenants) {
    arrayOfStep.push(
      {
        text: t('Click this button to view or edit tenant detail.'),
        attachTo: {
          element: '.shepherd-tenantList-editTenantButton-0',
          on: 'bottom-start',
        },
        buttons: [
          {
            text: t('Back'),
            action: function () {
              this.back();
            },
          },
          {
            text: !isSkipGuide ? t("Don't show again") : t('Complete'),
            action: function () {
              if (!isSkipGuide) {
                handleSkipCurrentTour(store, router, t)
              }
              this.complete();
            },
          }
        ],
      }
    )
  }

  return arrayOfStep
}

export function tenantDetailSteps(store, router, t, handleTabChange) {
  const isSkipGuide = store.user.isSkipTourGuide.tenants

  return [
    {
      text: t('Welcome to Tenant Detail Page! This guide will explain the features of this page.'),
      buttons: [
        {
          text: t('Skip Tour'),
          classes: !isSkipGuide ? 'show-skip' : 'hide-skip',
          secondary: true,
          action: function () {
            handleSkipCurrentTour(store, router, t)
            this.complete();
          },
        },
        {
          text: t('Start'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this button to add new bill.'),
      attachTo: {
        element: '.shepherd-tenant-add-bill',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this button to edit tenant information and rent'),
      attachTo: {
        element: '.shepherd-tenant-edit',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 0)
            this.next();
          },
        },
      ],
    },
    {
      text: t('This tab showing tenant profil'),
      attachTo: {
        element: '.shepherd-tenant-tab-profil',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 1)
            this.next();
          },
        },
      ],
    },
    {
      text: t('This tab showing tenant rent'),
      attachTo: {
        element: '.shepherd-tenant-tab-rent',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            handleTabChange(null, 0)
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 2)
            this.next();
          },
        },
      ],
    },
    {
      text: t('This tab showing tenant invoice'),
      attachTo: {
        element: '.shepherd-tenant-tab-invoice',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            handleTabChange(null, 1)
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 3)
            this.next();
          },
        },
      ],
    },
    {
      text: t('This tab showing tenant document'),
      attachTo: {
        element: '.shepherd-tenant-tab-doc',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            handleTabChange(null, 2)
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this button to end rent tenant'),
      attachTo: {
        element: '.shepherd-tenant-end-rent',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            handleTabChange(null, 3)
            this.back();
          },
        },
        {
          text: !isSkipGuide ? t("Don't show again") : t('Complete'),
          action: async function () {
            handleTabChange(null, 0)
            if (!isSkipGuide) {
              handleSkipCurrentTour(store, router, t)
            }
            this.complete();
          },
        },
      ],
      // buttons: backCompleteButton(store, router, handleTabChange),
    },
  ]
};

export function roomsSteps(store, router, t, handleTabChange) {
  const isSkipGuide = store.user.isSkipTourGuide.rooms
  const isEmptyRooms = store.room.filteredItems.length == 0

  let arrayOfStep = [
    {
      text: t('Welcome to Room Page! This guide will explain the features of this page.'),
      attachTo: {
        element: '.shepherd-currentPage',
        on: 'right-start'
      },
      buttons: [
        {
          text: t('Skip Tour'),
          classes: !isSkipGuide ? 'show-skip' : 'hide-skip',
          secondary: true,
          action: function () {
            handleSkipCurrentTour(store, router, t)
            this.complete();
          },
        },
        {
          text: t('Start'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this button to add new room.'),
      attachTo: {
        element: '.shepherd-roomList-addRoomButton',
        on: 'bottom-end',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Use this searchBar to find room.'),
      attachTo: {
        element: '.shepherd-searchBar',
        on: 'bottom-end',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Use this filter status button to find room by status.'),
      attachTo: {
        element: '.shepherd-toggleStatusMenu',
        on: 'bottom-start',
      },
      // extraHighlights: ['.shepherd-statusValue'],
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Use this filter property menu to find room by property.'),
      attachTo: {
        element: '.shepherd-filterPropertyMenu',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this tab to switch the display options, grid view or list view.'),
      attachTo: {
        element: '.shepherd-roomList-tabsView',
        on: 'bottom-end',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 0)
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this tab to view room list.'),
      attachTo: {
        element: '.shepherd-roomList-roomsTab',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            if (isEmptyRooms) {
              handleTabChange(null, 1);
            };
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this tab to view room schedule.'),
      attachTo: {
        element: '.shepherd-roomList-roomsScheduleTab',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            handleTabChange(null, 0);
            setTimeout(() => {
              this.back();
            }, 50);
          },
        },
        {
          text: !isSkipGuide ? t("Don't show again") : t('Complete'),
          action: async function () {
            handleTabChange(null, 0);
            if (!isSkipGuide) {
              handleSkipCurrentTour(store, router, t)
            }
            this.complete();
            // try {
            //   const dontShowAgainCheckbox = document.getElementById('dont-show-again');
            //   if (dontShowAgainCheckbox) {
            //     // hapus params
            //     const resolvedPath = router.asPath.split('/')[1];
            //     const pages = {
            //       [resolvedPath]: true,
            //     };

            //     if (dontShowAgainCheckbox.checked) {
            //       const response = await store.user.skipTour(store.user._id, pages);

            //       if (response.status === 200) {
            //         this.complete();
            //       } else {
            //         console.warn('Failed to skip tour, status:', response.status);
            //       }
            //     } else {
            //       this.complete();
            //     }
            //   } else {
            //     console.warn('Checkbox element not found!');
            //     this.complete();
            //   }
            // } catch (error) {
            //   console.error('Failed to skip tour:', error);
            //   this.complete();
            // }
          },
        }
      ],
    }
  ]

  if (!isEmptyRooms) {
    // Push steps
    arrayOfStep.splice(7, 0, {
      text: t('Click here to view and edit room detail.'),
      attachTo: {
        element: '.shepherd-roomList-roomCard-0',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 1)
            this.next();
          },
        }
      ],
    })
  }
  return arrayOfStep
}

export function roomDetailSteps(store, router, t, handleTabChange) {
  const isSkipGuide = store.user.isSkipTourGuide.rooms

  let arrayOfStep = [
    {
      id: 'intro',
      text: t('Welcome to Room Detail Page! This guide will explain the features of this page.'),
      attachTo: {
        element: '.shepherd-roomDetail',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Skip Tour'),
          classes: !isSkipGuide ? 'show-skip' : 'hide-skip',
          secondary: true,
          action: function () {
            handleSkipCurrentTour(store, router, t)
            this.complete();
          },
        },
        {
          text: t('Start'),
          action: function () {
            handleTabChange(null, 0)
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this tab to view or edit room information.'),
      attachTo: {
        element: '.shepherd-roomDetail-roomDetailTab',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 1)
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this tab to view, add or edit room expenses.'),
      attachTo: {
        element: '.shepherd-roomDetail-roomExpensesTab',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            handleTabChange(null, 0)
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Here is the property information of this room.'),
      attachTo: {
        element: '.shepherd-roomDetail-propertyInfo',
        on: 'bottom-start',
      },
      extraHighlights: ['.shepherd-dashboardCard-title'],
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Here is list of tenants who have stayed in this room until now.'),
      attachTo: {
        element: '.shepherd-roomDetail-occupancyHistory',
        on: 'bottom-start',
      },
      extraHighlights: ['.shepherd-dashboardCard-title'],
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this button to delete this room.'),
      attachTo: {
        element: '.shepherd-roomDetail-deleteRoomButton',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: !isSkipGuide ? t("Don't show again") : t('Complete'),
          action: async function () {
            handleTabChange(null, 0)
            if (!isSkipGuide) {
              handleSkipCurrentTour(store, router, t)
            }
            this.complete();
          },
        },
      ],
    },
  ]

  return arrayOfStep
}

export function propertiesSteps(store, router, t, handleTabChange) {
  const isSkipGuide = store.user.isSkipTourGuide.properties
  const isEmptyProperties = store.property.filteredItems.length == 0

  let arrayOfStep = [
    {
      text: t('Welcome to Property Page! This guide will explain the features of this page.'),
      attachTo: {
        element: '.shepherd-currentPage',
        on: 'bottom-start'
      },
      buttons: [
        {
          text: t('Skip Tour'),
          classes: !isSkipGuide ? 'show-skip' : 'hide-skip',
          secondary: true,
          action: function () {
            handleSkipCurrentTour(store, router, t)
            this.complete();
          },
        },
        {
          text: t('Start'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this button to add new property.'),
      attachTo: {
        element: '.shepherd-propertyList-addPropertyButton',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Use this searchBar to find property.'),
      attachTo: {
        element: '.shepherd-propertyList-searchBar',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: isEmptyProperties ? !isSkipGuide ? t("Don't show again") : t('Complete') : t('Next'),
          action: async function () {
            if (!isSkipGuide) {
              handleSkipCurrentTour(store, router, t)
            }
            isEmptyProperties ? this.complete() : this.next();
          },
        }
      ],
    },
  ]

  if (!isEmptyProperties) {
    arrayOfStep.push(
      {
        text: t('Click this button to view or edit property detail.'),
        attachTo: {
          element: '.shepherd-propertyList-editPropertyButton-0',
          on: 'bottom-start',
        },
        buttons: [
          {
            text: t('Back'),
            action: function () {
              this.back();
            },
          },
          {
            text: t('Next'),
            action: function () {
              this.next();
            },
          }
        ],
      },
      {
        text: t('Click this button to add new rooms on this property.'),
        attachTo: {
          element: '.shepherd-propertyList-addRoomsButton-0',
          on: 'bottom-start',
        },
        buttons: [
          {
            text: t('Back'),
            action: function () {
              this.back();
            },
          },
          {
            text: !isSkipGuide ? t("Don't show again") : t('Complete'),
            action: function () {
              if (!isSkipGuide) {
                handleSkipCurrentTour(store, router, t)
              }
              this.complete();
            },
          }
        ],
      },
    )
  }

  return arrayOfStep
}

export function propertyDetailSteps(store, router, t, handleTabChange) {
  const isSkipGuide = store.user.isSkipTourGuide.properties

  const arrayOfStep = [
    {
      id: 'intro',
      text: t('Welcome to Property Detail Page! This guide will explain the features of this page.'),
      attachTo: {
        element: '.shepherd-propertyDetail',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Skip Tour'),
          classes: !isSkipGuide ? 'show-skip' : 'hide-skip',
          secondary: true,
          action: function () {
            handleSkipCurrentTour(store, router, t)
            this.complete();
          },
        },
        {
          text: t('Start'),
          action: function () {
            handleTabChange(null, 0)
            this.next();
          },
        },
      ]
    },
    {
      text: t('Click this tab to view or edit property information.'),
      attachTo: {
        element: '.shepherd-editPropertyTab',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 1)
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this tab to view or edit property transfer details.'),
      attachTo: {
        element: '.shepherd-transferDetailTab',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            handleTabChange(null, 0)
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 2)
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this tab to view or edit property services.'),
      attachTo: {
        element: '.shepherd-serviceListTab',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            handleTabChange(null, 1)
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 3)
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this tab to view or edit property rooms.'),
      attachTo: {
        element: '.shepherd-roomListTab',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            handleTabChange(null, 2)
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 4)
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this tab to view or edit property invoice template.'),
      attachTo: {
        element: '.shepherd-invoiceTemplateTab',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            handleTabChange(null, 3)
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 5)
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this tab to view or edit property landing page.'),
      attachTo: {
        element: '.shepherd-landingPageTab',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            handleTabChange(null, 4)
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this button to add new service on this property.'),
      attachTo: {
        element: '.shepherd-propertyDetail-addServiceButton',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Click this button to delete this property.'),
      attachTo: {
        element: '.shepherd-propertyDetail-deleteButton',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: !isSkipGuide ? t("Don't show again") : t('Complete'),
          action: async function () {
            handleTabChange(null, 3)
            if (!isSkipGuide) {
              handleSkipCurrentTour(store, router, t)
            }
            this.complete();
          },
        }
      ],
    },
  ]

  return arrayOfStep
}

export function expensesSteps(store, router, t, handleTabChange) {
  const isSkipGuide = store.user.isSkipTourGuide.expenses

  return [
    {
      text: t('Welcome to Expense Page! Let me walk you through its features.'),
      attachTo: {
        element: '.shepherd-currentPage',
        on: 'right-start'
      },
      buttons: [
        {
          text: t('Skip Tour'),
          classes: !isSkipGuide ? 'show-skip' : 'hide-skip',
          secondary: true,
          action: function () {
            handleSkipCurrentTour(store, router, t)
            this.complete();
          },
        },
        {
          text: t('Start'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this button to add a new expense.'),
      attachTo: {
        element: '.shepherd-expense-new-button',
        on: 'bottom-end',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Use this button to export expenses to Excel.'),
      attachTo: {
        element: '.shepherd-expense-export-button',
        on: 'bottom-end',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Here, you can filter expenses by year, property, month, category, and room.'),
      attachTo: {
        element: '.shepherd-expense-filter',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('This section displays the expense graph for better insights.'),
      attachTo: {
        element: '.shepherd-expense-graph',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Below, you will find a detailed list of your expenses.'),
      attachTo: {
        element: '.shepherd-expense-card-list',
        on: 'top',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: !isSkipGuide ? t("Don't show again") : t('Complete'),
          action: async function () {
            if (!isSkipGuide) {
              handleSkipCurrentTour(store, router, t)
            }
            this.complete();
          },
        },
      ],
    },
  ]
};

export function settingSteps(store, router, t, handleTabChange) {
  const isSkipGuide = store.user.isSkipTourGuide.settings

  let arrayOfStep = [
    {
      text: t('Welcome to Setting Page! This guide will explain the features of this page.'),
      attachTo: {
        element: '.shepherd-currentPage',
        on: 'right-start'
      },
      buttons: [
        {
          text: t('Skip Tour'),
          classes: !isSkipGuide ? 'show-skip' : 'hide-skip',
          secondary: true,
          action: function () {
            handleSkipCurrentTour(store, router, t)
            this.complete();
          },
        },
        {
          text: t('Start'),
          action: function () {
            handleTabChange(null, 0)
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this tab to view or edit account information.'),
      attachTo: {
        element: '.shepherd-setting-tabAccountInfo',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this button to remove all tenants.'),
      attachTo: {
        element: '.shepherd-setting-removeTenants',
        on: 'top-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this button to reset account data.'),
      attachTo: {
        element: '.shepherd-setting-resetData',
        on: 'top-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 1)
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this tab to view, add or edit team member.'),
      attachTo: {
        element: '.shepherd-setting-tabMemberAdmin',
        on: 'top-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            handleTabChange(null, 0)
            setTimeout(() => {
              this.back();
            }, 50);
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this button to add new account as admin.'),
      attachTo: {
        element: '.shepherd-setting-addAdmin',
        on: 'top-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 2)
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this tab to view or edit template & notification setting.'),
      attachTo: {
        element: '.shepherd-setting-tabRealmNotification',
        on: 'top-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            handleTabChange(null, 1)
            setTimeout(() => {
              this.back();
            }, 50);
          },
        },
        {
          text: t('Next'),
          action: function () {
            handleTabChange(null, 3)
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this tab to view or edit payment method.'),
      attachTo: {
        element: '.shepherd-setting-tabPaymentMethod',
        on: 'top-start',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            handleTabChange(null, 2)
            this.back();
          },
        },
        {
          text: !isSkipGuide ? t("Don't show again") : t('Complete'),
          action: async function () {
            handleTabChange(null, 0)
            if (!isSkipGuide) {
              handleSkipCurrentTour(store, router, t)
            }
            this.complete();
          },
        }
      ],
    },
  ]

  return arrayOfStep
}

/**
 * Forms or Stepper Guide
 * @param {*} store 
 * @param {*} router 
 * @param {*} t 
 * @param {*} handleTabChange 
 */

export const tenantBioStepperGuide = (t, store, router) => {
  const isSkipGuide = store.user.isSkipTourGuide.tenants

  return [
    {
      text: t("Enter the tenant's full name (e.g., John Doe)."),
      attachTo: {
        element: '.tenant-stepper-bio-name',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Skip Tour'),
          classes: !isSkipGuide ? 'show-skip' : 'hide-skip',
          secondary: true,
          action: function () {
            handleSkipCurrentTour(store, router, t)
            this.complete();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t("Provide the tenant's official identification number (e.g., ID card number or passport number)."),
      attachTo: {
        element: '.tenant-stepper-bio-id',
        on: 'bottom-end',
      },
      buttons: backNextButton,
    },
    {
      text: t('Select the appropriate gender (e.g., Male or Female).'),
      attachTo: {
        element: '.tenant-stepper-bio-gender',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Choose the current marital status (e.g., Single, Married, Divorced).'),
      attachTo: {
        element: '.tenant-stepper-bio-marital',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t("Fill in the tenant's profession or job title (e.g., Software Engineer, Teacher)."),
      attachTo: {
        element: '.tenant-stepper-bio-job',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t("If applicable, enter the tenant's vehicle registration number."),
      attachTo: {
        element: '.tenant-stepper-bio-vehicle',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t("Add any special remarks or notes for the tenant’s entry (e.g., special needs or conditions)."),
      attachTo: {
        element: '.tenant-stepper-bio-notes',
        on: 'bottom-end',
      },
      buttons: backNextButton,
    },
    {
      text: t("Enter the tenant's contact information Name, Email, Whatsapp Number"),
      attachTo: {
        element: '.tenant-stepper-bio-contact',
        on: 'bottom-end',
      },
      buttons: backNextButton,
    },
    {
      text: t(`Once all fields are filled, click the SAVE button to submit the form.`),
      attachTo: {
        element: '.tenant-stepper-bio-save',
        on: 'bottom-end',
      },
      buttons: backCompleteButton(store, router),
    },
  ]
};

export const tenantRentStepperGuide = (t, store, router) => {
  const isSkipGuide = store.user.isSkipTourGuide.tenants

  return [
    {
      text: t("Select the start date for the rental period."),
      attachTo: {
        element: '.tenant-stepper-rent-start',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Skip Tour'),
          classes: !isSkipGuide ? 'show-skip' : 'hide-skip',
          secondary: true,
          action: function () {
            handleSkipCurrentTour(store, router, t)
            this.complete();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Select the specific room you are renting from the dropdown list.'),
      attachTo: {
        element: '.tenant-stepper-rent-room',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Specify the rental period, such as daily, monthly, yearly, or a custom duration.'),
      attachTo: {
        element: '.tenant-stepper-rent-period',
        on: 'top-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('Review the rental price. The price is displayed as Rp 0 by default, but it will update based on the room and period selection.'),
      attachTo: {
        element: '.tenant-stepper-rent-price',
        on: 'top-start',
      },
      buttons: backNextButton,
    },
    {
      text: t("Confirm the security deposit (Uang Jaminan) amount. This value is often pre-filled."),
      attachTo: {
        element: '.tenant-stepper-rent-depo',
        on: 'top-start',
      },
      buttons: backNextButton,
    },
    {
      text: t("Verify the Down Payment (Uang Muka) amount, which is essential to secure the room."),
      attachTo: {
        element: '.tenant-stepper-rent-dp',
        on: 'top-start',
      },
      buttons: backNextButton,
    },
    {
      text: t(`Use the "Lunas" checkbox to mark the rental payment or down payment as fully paid.`),
      attachTo: {
        element: '.tenant-stepper-rent-paid',
        on: 'top-end',
      },
      buttons: backNextButton,
    },
    {
      text: t("After filling out all fields and confirming the details, click the yellow “SIMPAN” button to save the form."),
      attachTo: {
        element: '.tenant-stepper-rent-save',
        on: 'bottom-end',
      },
      buttons: backCompleteButton(store, router),
    },
  ]
};

export const tenantDocStepperGuide = (t, store, router) => {
  const isSkipGuide = store.user.isSkipTourGuide.tenants

  return [
    {
      text: t("Upload important documents for the tenant, such as an ID card (KTP), passport, or other required files."),
      attachTo: {
        element: '.tenant-stepper-doc-upload',
        on: 'bottom-start',
      },
      buttons: [
        {
          text: t('Skip Tour'),
          classes: !isSkipGuide ? 'show-skip' : 'hide-skip',
          secondary: true,
          action: function () {
            handleSkipCurrentTour(store, router, t)
            this.complete();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        },
      ],
    },
    {
      text: t('Once the documents are uploaded, they will appear in the document list area.'),
      attachTo: {
        element: '.tenant-stepper-doc-list',
        on: 'bottom-start',
      },
      buttons: backNextButton,
    },
    {
      text: t('After uploading and verifying the tenant documents, click the “SIMPAN” button to save and submit the information.'),
      attachTo: {
        element: '.tenant-stepper-doc-save',
        on: 'top-start',
      },
      buttons: backCompleteButton(store, router),
    },
  ]
};

export function newRoomFormSteps(store, router, t, handleTabChange) {
  let arrayOfStep = [
    {
      text: t('This is Add New Room Form! This guide will explain the fields of this form.'),
      attachTo: {
        element: '.shepherd-newRoomDialog-title',
        on: 'bottom-start'
      },
      extraHighlights: ['.shepherd-newRoomDialog'],
      buttons: [
        {
          text: t('Start'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Input room name here.'),
      attachTo: {
        element: '.shepherd-newRoomDialog-nameField',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Check this to bulk create room.'),
      attachTo: {
        element: '.shepherd-newRoomDialog-bulkCheckbox',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            // TODO: Set checkbox to true
            this.next();
          },
        }
      ],
    },
    // {
    //   text: t('Input amount room for bulk create.'),
    //   attachTo: {
    //     element: '.shepherd-newRoomDialog-bulkAmountField',
    //     on: 'bottom',
    //   },
    //   buttons: [
    //     {
    //       text: t('Back'),
    //       action: function () {
    //         // TODO: Set checkbox to false
    //         this.back();
    //       },
    //     },
    //     {
    //       text: t('Next'),
    //       action: function () {
    //         // TODO: Set checkbox to false
    //         this.next();
    //       },
    //     }
    //   ],
    // },
    {
      text: t('Select the property for this rooms.'),
      attachTo: {
        element: '.shepherd-newRoomDialog-propertySelect',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Input room note if you have any. This is an optional field.'),
      attachTo: {
        element: '.shepherd-newRoomDialog-noteField',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('These are prices for this room'),
      attachTo: {
        element: '.shepherd-newRoomDialog-pricesField',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this button to cancel.'),
      attachTo: {
        element: '.shepherd-cancelButton',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this button to submit data.'),
      attachTo: {
        element: '.shepherd-submitButton',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Complete'),
          action: function () {
            this.complete();
          },
        }
      ],
    },
  ]

  return arrayOfStep
}

export function newPropertyFormSteps(store, router, t, handleTabChange) {
  let arrayOfSteps = [
    {
      text: t('This is Add New Property Form! This guide will explain the fields of this form.'),
      attachTo: {
        element: '.shepherd-newPropertyDialog-title',
        on: 'bottom-start'
      },
      extraHighlights: ['.shepherd-newPropertyDialog'],
      buttons: [
        {
          text: t('Start'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Input property name here.'),
      attachTo: {
        element: '.shepherd-newPropertyDialog-nameField',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Input property address here.'),
      attachTo: {
        element: '.shepherd-newPropertyDialog-addressField',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Input property phone number here.'),
      attachTo: {
        element: '.shepherd-newPropertyDialog-phoneField',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Input property note if you have any. This is an optional field'),
      attachTo: {
        element: '.shepherd-newPropertyDialog-noteField',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('These are bank information for this property'),
      attachTo: {
        element: '.shepherd-newPropertyDialog-bankInformation',
        on: 'bottom',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this button to cancel.'),
      attachTo: {
        element: '.shepherd-cancelButton',
        on: 'bottom-end',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Next'),
          action: function () {
            this.next();
          },
        }
      ],
    },
    {
      text: t('Click this button to submit data.'),
      attachTo: {
        element: '.shepherd-submitButton',
        on: 'bottom-end',
      },
      buttons: [
        {
          text: t('Back'),
          action: function () {
            this.back();
          },
        },
        {
          text: t('Complete'),
          action: function () {
            this.complete();
          },
        }
      ],
    },
  ]

  return arrayOfSteps
}